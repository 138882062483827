<template>
<div>
  <div>
    <b-col class="content-header-left mb-1" cols="12" md="12">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
            Follow Up
        </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb style="font-size: 0.9rem">
                <b-breadcrumb-item to="/">
                  <feather-icon icon="HomeIcon" size="14" class="align-text-top" />
                </b-breadcrumb-item>
                <b-breadcrumb-item active>
                  Followup
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </div>
  <section id="dashboard-analytics">
    <b-row class="match-height" v-if="userRole=='channelpartner'">
      <b-col lg="6">
        <analytics-support-tracker :data="data.supportTracker" />
      </b-col>
    </b-row>

  </section>
</div>
</template>

<script>
import { BRow, BCol, BButton, BBreadcrumb,
  BBreadcrumbItem } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
import { kFormatter } from '@core/utils/filter'


export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BButton,
    kFormatter,
    AnalyticsSupportTracker,
    StatisticCardVertical,
    BRow,
    BCol
   
    // InvoiceList,
  },
  data () {
    return {
      data:{},
      userData: localStorage.getItem('userData'),
      userRole: ''
    }
  },
  created () {
    // data
    this.$http.get('/analytics/data').then((response) => {
      this.data = response.data
    })
  },
  mounted () {
    this.userRole = JSON.parse(this.userData).role
  },
  methods: {
    kFormatter

  }
}
</script>
